<!--采购退货申请-->
<template>
    <div class="Refund">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                v-model="form"
                size="small"
                style="text-align: left"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="单据号">
                            <el-input placeholder="" v-model="form.code" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退货机构">
                            <el-select placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in stores"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="供货机构">
                            <el-select placeholder="请选择" v-model="form.repoCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.repos"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="审核状态">
                            <el-select placeholder="请选择" v-model="form.reviewStatus">
                                <el-option
                                    v-for="e in meta.reviewEnums"
                                    :label="e.label"
                                    :value="e.code"
                                    :key="e.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.purchase.refund.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.purchase.refund.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="handleAudit"
                size="small"
                v-if="hasPrivilege('menu.purchase.refund.review')"
                >审核
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.purchase.refund.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" baseUrl="/purchase/refund/apply" @completed="handleQuery" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                ref="table"
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="编码" width="220" prop="code" v-if="showColumn('code')" />
                <el-table-column label="退货机构" width="160" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="供货机构" width="160" prop="repoName" v-if="showColumn('repoName')" />
                <el-table-column
                    label="单据金额"
                    width="140"
                    prop="bizMoney"
                    v-if="showColumn('bizMoney') && showInPrice()"
                    key="bizMoney"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.bizMoney | formatMoney }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="审核状态"
                    width="140"
                    prop="reviewStatus"
                    key="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="160" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="180"
                    header-align="center"
                    prop="operate"
                    key="operate"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.purchase.refund.open')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.purchase.refund.edit')"
                            :disabled="scope.row.reviewStatus === 2"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.purchase.refund.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import MoneyUtils from 'js/MoneyUtils';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfReviewBiz from 'components/EfReviewBiz';
import { DeptTypeEnum } from 'js/DeptCommon';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
export default {
    name: 'PurchaseRefund',
    components: { EfEndDate, EfStartDate, EfReviewBiz, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                code: '',
                deptCode: '',
                repoCode: '',
                reviewStatus: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            auditCodes: [],
            audit: {
                auditDate: '',
                advice: '1',
                remark: '',
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                delete: '/purchase/refund/apply/delete',
                page: '/purchase/refund/apply/page',
            },
            meta: {
                startTime: '',
                endTime: '',
                repos: [],
                reviewEnums: Util.reviewStatusEnum(true),
            },
            stores: [],
        };
    },
    mounted() {
        const _this = this;
        Promise.all([
            _this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE),
            _this.$efApi.deptApi.companyAllRepos(),
        ]).then((rst) => {
            _this.stores = rst[0];
            _this.form.deptCode = _this.stores[0].code;
            _this.meta.repos = rst[1];
            _this.form.repoCode = _this.meta.repos[0].code;
            _this.handleQuery();
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleAudit() {
            this.$refs.review.open(this.$refs.table.selection);
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.purchase.refund.create', ['采购管理', '退货申请', '新建退货']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.purchase.refund.detail', ['采购管理', '退货申请', '退货详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            if (row.reviewStatus != 0) {
                this.$message.error('非待审核退货单不能编辑');
            } else {
                Util.nameJump(this, 'menu.purchase.refund.edit', ['采购管理', '退货申请', '编辑退货'], {
                    form: row,
                    code: row.code,
                });
            }
        },
        handleDelete(row) {
            if (row.reviewStatus != 0) {
                this.$message.error('非待审核退货单不能删除');
            } else {
                UrlUtils.DeleteRemote(this, this.url.delete, row.code);
            }
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '退货申请', '/purchase/refund/apply/export', this.form, codes);
        },
    },
    filters: {
        formatMoney(money) {
            return MoneyUtils.moneyToYuan(money);
        },
    },
};
</script>
